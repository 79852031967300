<template>
  <div
    class="table-container"
    :class="{ 'alter-table-container': grades_report && !report_thirth }"
  >
    <div class="table-header">
      {{ table_header }}
    </div>
    <b-table
      striped
      hover
      :items="items"
      :fields="
        [{ key: 'section', label: this.column_name, sortable: true }].concat(
          fields
        )
      "
      small
      responsive
      thead-class="custom-header small"
      table-class="simple-report-table small"
      bordered
    >
      <template v-slot:head()="data">
        <div class="custom-table-header">
          <template v-if="!grades_report && !report_thirth">
            <div
              v-b-tooltip.top.v-secondary.noninteractive="
                headerTooltip(data.field.label)
              "
            >
              {{ data.field.label }}
            </div>
          </template>
          <template v-else>
            <template v-if="data.field.label.toLowerCase().includes('nota')">
              <div v-b-tooltip.top.v-secondary.noninteractive="'(Promedio)'">
                {{ data.field.label }}
              </div>
            </template>
            <template
              v-else-if="
                data.field.label.toLowerCase().includes('perfil de egreso')
              "
            >
              {{
                $getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil De Egreso"
                ).toUpperCase()
              }}
            </template>
            <template v-else>
              {{ data.field.label }}
            </template>
          </template>
        </div>
      </template>
      <template v-slot:cell()="data">
        <template v-if="grades_report">
          <div
            v-if="
              (chartdata.extra_data &&
                data.field.label.toLowerCase().includes('nota')) ||
              data.field.label.toLowerCase().includes('promedio')
            "
            :class="{
              'bg-red': data.value <= chartdata.extra_data.grades_ranges[0][2],
              'bg-yellow':
                data.value >= chartdata.extra_data.grades_ranges[1][1] &&
                data.value <= chartdata.extra_data.grades_ranges[1][2],
              'bg-green':
                data.value >= chartdata.extra_data.grades_ranges[2][1],
              'bg-transparent': data.value == '',
            }"
          >
            <span class="data-value">
              {{ data.value }}
            </span>
          </div>
          <div
            v-else-if="
              chartdata.extra_data &&
              data.field.label != 'N°' &&
              data.field.label != 'AÑO DE INGRESO'
            "
            :class="{
              'bg-red': data.value <= chartdata.extra_data.grades_ranges[0][2],
              'bg-yellow':
                data.value >= chartdata.extra_data.grades_ranges[1][1] &&
                data.value <= chartdata.extra_data.grades_ranges[1][2],
              'bg-green':
                data.value >= chartdata.extra_data.grades_ranges[2][1] &&
                data.value <= chartdata.extra_data.grades_ranges[2][2],
              'bg-transparent': data.value == '',
            }"
          >
            {{ data.value }}
          </div>

          <div v-else>
            {{ data.value }}
          </div>
        </template>
      </template>
    </b-table>
    <template v-if="!chartdata.datasets.length">
      <div class="empty-dataset-alert">
        <b-icon icon="exclamation-circle" class="mr-1"></b-icon> No hay datos
        para mostrar.
      </div>
    </template>
    <template v-if="chartdata.extra_data">
      <div class="report-ranges-caption mb-2">
        <div class="report-ranges-header">
          <div class="ranges-header">RANGOS DE NOTAS</div>
        </div>
        <template
          v-for="(grade_range, index) in chartdata.extra_data.grades_ranges"
        >
          <div class="report-range" :key="grade_range.id">
            <span class="range-label">
              {{ chartdata.extra_data.grades_ranges[index][0] }}
            </span>
            <div
              :class="{
                'range-value-red': index == 0,
                'range-value-yellow': index == 1,
                'range-value-green': index == 2,
              }"
            >
              {{ chartdata.extra_data.grades_ranges[index][1] }} -
              {{ chartdata.extra_data.grades_ranges[index][2] }}
            </div>
          </div>
        </template>
      </div>
    </template>
    <template>
      <download-excel
        class="btn btn-primary mt-2"
        :data="itemsXLS"
        :fields="jsonFields"
        worksheet="REPORTE DE NOTAS (ESTUDIANTES)"
        name="Reporte Notas Estudiantes por Nivel.xls"
        :header="headerArray"
        :footer="footerArray"
      >
        Descargar XLS
      </download-excel>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "PlanLevelGradesPerStudent",
  props: ["chartdata", "selectors"],
  data() {
    return {
      section_name: "",
      label_tooltip: "",
      grades_report: true,
      report_thirth: false,
      column_name: "N°",
      table_header: "REPORTE DE NOTAS DE ESTUDIANTES POR NIVEL",
    };
  },
  computed: {
    ...mapGetters({
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
    }),
    items() {
      let list = [];
      if (!this.grades_report && !this.report_thirth) {
        this.chartdata.datasets.forEach((elements) => {
          // let total = 0;
          let row = { section: elements.label };
          for (let index = 0; index < elements.data.length; index++) {
            let label = this.chartdata.labels[index][0];
            let weighing = this.chartdata.labels[index][2];
            if (index > 3) {
              label = label + " - " + weighing + "%";
            }
            const data = elements.data[index];
            if (!isNaN(data) && index > 3 && data != null) {
              row[label] = data + "%";
            } else if (data == null) {
              row[label] = "-";
            } else {
              row[label] = data;
            }
          }
          list.push(row);
        });
      } else {
        this.chartdata.datasets.forEach((elements) => {
          let row = { section: elements.label };
          for (let index = 0; index < elements.data.length; index++) {
            let label = this.chartdata.labels[index];
            let data = elements.data[index];
            if (!isNaN(data) && label.includes("PONDERACIÓN") && data != "") {
              data = data + "%";
            }
            row[label] = data;
          }
          list.push(row);
        });
      }
      return list;
    },
    itemsXLS() {
      let list = [];
      if (!this.grades_report) {
        this.chartdata.datasets.forEach((elements) => {
          let row = { section: elements.label };
          for (let index = 0; index < elements.data.length; index++) {
            let label = this.chartdata.labels[index][0];
            let weighing = this.chartdata.labels[index][2];
            if (index > 3) {
              label = label + " - " + weighing + "%";
            }
            const data = elements.data[index];
            if (!isNaN(data) && index > 3 && data != null) {
              row[label] = data + "%";
            } else if (data == null) {
              row[label] = "-";
            } else {
              row[label] = data;
            }
          }
          list.push(row);
        });
      } else {
        this.chartdata.datasets.forEach((elements) => {
          // let total = 0;
          let row = { section: elements.label };
          for (let index = 0; index < elements.data.length; index++) {
            let label = this.chartdata.labels[index];
            let data = elements.data[index];
            if (!isNaN(data) && label.includes("PONDERACIÓN") && data != "") {
              data = data + "%";
            }
            row[label] = data;
          }
          list.push(row);
        });
      }
      return list;
    },
    fields() {
      let list = [];
      if (!this.grades_report && !this.report_thirth) {
        for (let index = 0; index < this.chartdata.labels.length; index++) {
          let column_header = this.chartdata.labels[index][0];
          let weighing = this.chartdata.labels[index][2];
          if (index > 3) {
            column_header = column_header + " - " + weighing + "%";
          }
          list.push(column_header);
        }
      } else {
        for (let index = 0; index < this.chartdata.labels.length; index++) {
          let column_header = this.chartdata.labels[index];
          list.push(column_header);
        }
      }
      return list;
    },
    footers() {
      let list = [];
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        let counter = 0;
        this.items.forEach((section) => {
          counter += section[this.chartdata.labels[index]];
        });
        list.push(counter);
      }
      return list;
    },
    jsonFields() {
      let json_fields = { "N°": "section" };
      this.fields.forEach((label) => {
        json_fields[label] = label;
      });
      return json_fields;
    },
    jsonFooter() {
      let json_footer = { section: "Totales" };
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        const label = this.chartdata.labels[index];
        const data = this.footers[index];
        json_footer[label] = data;
      }
      return json_footer;
    },
  },
  methods: {
    headerArray() {
      let list = "";
      list +=
        "<tr>" +
        `<th colspan='${
          this.chartdata.labels.length + 1
        }' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
        this.table_header +
        "</th>" +
        "</tr>";
      if (this.selectors.selected_campuses.length > 0) {
        list +=
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "CAMPUS: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_campuses[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_egress_profiles) {
        list +=
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil De Egreso"
          ).toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_egress_profiles[0].professional_title.toUpperCase() +
          "</b>" +
          "</td>";
      }

      if (this.selectors.selected_cohorts) {
        list +=
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "AÑO DE INGRESO: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          this.selectors.selected_cohorts[0].cohort +
          "</b>" +
          "</td>";
      }
      return list;
    },
    footerArray() {
      let list = [];
      list = list.concat(
        "<tr>" +
          `<th colspan='8' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
          "RANGOS DE NOTAS" +
          "</th>" +
          "</tr>" +
          "<tbody>" +
          "<tr>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "INSUFICIENTE: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          "1.0 - 3.9" +
          "</b>" +
          "</td>" +
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "SUFICIENTE: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          "4.0 - 5.5" +
          "</b>" +
          "</td>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "DESTACADO: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          "5.5 - 7.0" +
          "</b>" +
          "</td>" +
          "</tr>" +
          "</tbody>"
      );
      return list;
    },
    headerTooltip(data_label) {
      let tooltip_content = "";
      this.chartdata.labels.forEach((label) => {
        if (label[0] == data_label) {
          tooltip_content = label[1];
        }
      });
      return tooltip_content;
    },
    headerWeighing(data_label) {
      let tooltip_content = "";
      this.chartdata.labels.forEach((label) => {
        if (label[0] == data_label) {
          tooltip_content = label[2];
        }
      });
      return tooltip_content;
    },
  },
  mounted() {
    if (this.section) {
      this.$store
        .dispatch(names.FETCH_SECTION, this.section)
        .then((response) => {
          this.section_name = response.name;
        });
    } else {
      this.section_name = "SIN SELECCIONAR";
    }
  },
};
</script>

<style scoped>
.table-container {
  scroll-behavior: auto;
}
.table-thead {
  font-size: 5pt;
}
.table-header {
  background: var(--primary-color);
  font-weight: bold;
  padding: 0.2em;
}
.table-caption {
  display: flex;
}
.student-header,
.achievement-header {
  font-size: 11pt;
  padding: 0.2em;
  background: var(--primary-hover-color);
  overflow-x: hidden;
}
.student-header {
  min-width: 40%;
}
.achievement-header {
  width: 70%;
}
.table-foot {
  background-color: darkgrey;
}
.table-responsive >>> .table {
  text-align: center !important;
}
.table-container >>> .custom-header {
  background: var(--primary-hover-color) !important;
}
.table-container >>> .table thead th {
  vertical-align: middle !important;
}
.bg-red,
.bg-yellow,
.bg-green {
  height: 100% !important;
  vertical-align: middle;
  bottom: 50% !important;
  padding-top: 5px;
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.alter-table-container >>> .table td {
  vertical-align: middle;
}
.report-ranges-caption {
  width: 100%;
  border: 1px solid var(--primary-hover-color);
}
.report-ranges-header {
  display: flex;
  text-align: left;
  padding: 0.5em 1em;
  padding-left: 2em;
  background: var(--primary-hover-color);
  color: var(--secondary-font-color);
  font-weight: bold;
}
.report-range {
  display: flex;
  margin-left: 0;
  padding: 0.5em 4em;
}
.range-label {
  text-align: left;
  width: 15%;
}
.report-range .range-value-red {
  background: #f19292;
  width: 15%;
}
.report-range .range-value-yellow {
  background: #fff1ac;
  width: 15%;
}
.report-range .range-value-green {
  background: #bbeaa6;
  width: 15%;
}
.empty-dataset-alert {
  margin: 2em 0;
  font-size: 13pt;
}
.ranges-header {
  width: 40%;
}
.students-quantity-header,
.students-percentage-header {
  width: 30%;
}

.students-quantity,
.students-percentage {
  margin-left: 4rem !important;
  background-color: var(--primary-color);
  color: var(--secondary-font-color);
  width: 25%;
}
</style>