var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container",class:{ 'alter-table-container': _vm.grades_report && !_vm.report_thirth }},[_c('div',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.table_header)+" ")]),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":[{ key: 'section', label: this.column_name, sortable: true }].concat(
        _vm.fields
      ),"small":"","responsive":"","thead-class":"custom-header small","table-class":"simple-report-table small","bordered":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',{staticClass:"custom-table-header"},[(!_vm.grades_report && !_vm.report_thirth)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.v-secondary.noninteractive",value:(
              _vm.headerTooltip(data.field.label)
            ),expression:"\n              headerTooltip(data.field.label)\n            ",modifiers:{"top":true,"v-secondary":true,"noninteractive":true}}]},[_vm._v(" "+_vm._s(data.field.label)+" ")])]:[(data.field.label.toLowerCase().includes('nota'))?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.v-secondary.noninteractive",value:('(Promedio)'),expression:"'(Promedio)'",modifiers:{"top":true,"v-secondary":true,"noninteractive":true}}]},[_vm._v(" "+_vm._s(data.field.label)+" ")])]:(
              data.field.label.toLowerCase().includes('perfil de egreso')
            )?[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil De Egreso" ).toUpperCase())+" ")]:[_vm._v(" "+_vm._s(data.field.label)+" ")]]],2)]}},{key:"cell()",fn:function(data){return [(_vm.grades_report)?[(
            (_vm.chartdata.extra_data &&
              data.field.label.toLowerCase().includes('nota')) ||
            data.field.label.toLowerCase().includes('promedio')
          )?_c('div',{class:{
            'bg-red': data.value <= _vm.chartdata.extra_data.grades_ranges[0][2],
            'bg-yellow':
              data.value >= _vm.chartdata.extra_data.grades_ranges[1][1] &&
              data.value <= _vm.chartdata.extra_data.grades_ranges[1][2],
            'bg-green':
              data.value >= _vm.chartdata.extra_data.grades_ranges[2][1],
            'bg-transparent': data.value == '',
          }},[_c('span',{staticClass:"data-value"},[_vm._v(" "+_vm._s(data.value)+" ")])]):(
            _vm.chartdata.extra_data &&
            data.field.label != 'N°' &&
            data.field.label != 'AÑO DE INGRESO'
          )?_c('div',{class:{
            'bg-red': data.value <= _vm.chartdata.extra_data.grades_ranges[0][2],
            'bg-yellow':
              data.value >= _vm.chartdata.extra_data.grades_ranges[1][1] &&
              data.value <= _vm.chartdata.extra_data.grades_ranges[1][2],
            'bg-green':
              data.value >= _vm.chartdata.extra_data.grades_ranges[2][1] &&
              data.value <= _vm.chartdata.extra_data.grades_ranges[2][2],
            'bg-transparent': data.value == '',
          }},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',[_vm._v(" "+_vm._s(data.value)+" ")])]:_vm._e()]}}])}),(!_vm.chartdata.datasets.length)?[_c('div',{staticClass:"empty-dataset-alert"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" No hay datos para mostrar. ")],1)]:_vm._e(),(_vm.chartdata.extra_data)?[_c('div',{staticClass:"report-ranges-caption mb-2"},[_vm._m(0),_vm._l((_vm.chartdata.extra_data.grades_ranges),function(grade_range,index){return [_c('div',{key:grade_range.id,staticClass:"report-range"},[_c('span',{staticClass:"range-label"},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.grades_ranges[index][0])+" ")]),_c('div',{class:{
              'range-value-red': index == 0,
              'range-value-yellow': index == 1,
              'range-value-green': index == 2,
            }},[_vm._v(" "+_vm._s(_vm.chartdata.extra_data.grades_ranges[index][1])+" - "+_vm._s(_vm.chartdata.extra_data.grades_ranges[index][2])+" ")])])]})],2)]:_vm._e(),[_c('download-excel',{staticClass:"btn btn-primary mt-2",attrs:{"data":_vm.itemsXLS,"fields":_vm.jsonFields,"worksheet":"REPORTE DE NOTAS (ESTUDIANTES)","name":"Reporte Notas Estudiantes por Nivel.xls","header":_vm.headerArray,"footer":_vm.footerArray}},[_vm._v(" Descargar XLS ")])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-ranges-header"},[_c('div',{staticClass:"ranges-header"},[_vm._v("RANGOS DE NOTAS")])])
}]

export { render, staticRenderFns }